import { mixinCtrls } from "@/mixins/mixinCtrls";

export var mixinMto_inRow = {
  mixins: [mixinCtrls],
  data() {
    return {      
      record: null
    };
  },

  // obtengo entorno y schema
  async created() {
    await this.get_entorno_Mto_inRow();
    await this.get_schema_Mto_inRow();
    await this.get_records();
  },

  methods: {
    // entorno genérico
    get_entorno_Mto_inRow() {},

    // schema genérico
    async get_schema_Mto_inRow() {
      if (this.schema) return;
      this.schema = await this.$store.dispatch("get_schema", {
        tipo: "mto",
        name: this.Entorno.api
      });
    },

    // obtengo los registros del Grid
    get_records() {},

    //
    async acciones_Mto_inRow(evt) {
      console.log("*** acciones_Mto_inRow. Accion: " + evt.accion + " ***");

      // si no recibo acción, salgo
      if (!evt) return;
      if (evt.accion === "") return;

      // obtengo la acción a ejecutar (0:'ver', 1:'nuevo', 2:'editar', 3:'borrar', 4:'guardar', 5:'cancelar', 6:'salir')
      let A_acciones = [
        "ver",
        "nuevo",
        "editar",
        "borrar",
        "guardar",
        "cancelar",
        "salir"
      ];
      let accion = /^([0-9])*$/.test(evt.accion)
        ? A_acciones[evt.accion]
        : evt.accion;

      // ejecuto acción
      if (this.$isFunction(this[accion])) {
        await this[accion](evt);
        return;
      }
      this.$emit("onEvent", evt);
    },

    // edito fila recibida y guardo una copia de la misma
    async editar(evt) {
      if (this.Entorno.grid.row.row_inEdition) return;

      // paso fila a schema
      await this.record2ctrl(evt.row.item, this.schema.ctrls);

      // pongo estado a 'editar' y guardo los datos de la fila
      this.Entorno.estado = "editar";
      this.Entorno.grid.row.row_inEdition = evt.row.index;
      this.Entorno.grid.row.read_row = JSON.parse(JSON.stringify(evt.row.item));
    },

    // cancelo la edición de la fila regresando los datos de los campos
    // al valor que tenían a la hora de la edición
    cancelar(evt) {
      switch (this.Entorno.estado) {
        case "editar":
          this.cancelar_editar(evt);
          break;

        case "nuevo":
          this.cancelar_nuevo();
          break;
      }

      this.Entorno.grid.row.row_inEdition = null;
      this.Entorno.estado = "";
    },

    // restauro los datos de los campos editados
    cancelar_editar(evt) {
      var ctrls = this.schema.ctrls;
      for (const key in ctrls) {
        evt.row.item[key] = this.Entorno.grid.row.read_row[key];
      }
    },

    // elimino el último registro del Finder
    cancelar_nuevo() {
      //this.Entorno.records.records.pop();
      this.Entorno.records.records.shift();
    },

    //
    nuevo() {
      if (this.Entorno.grid.row.row_inEdition) return;

      this.record2ctrl({}, this.schema.ctrls, true);

      // item de ctrls vacío
      var item = this.ctrl2linea(this.schema.ctrls);

      // nuevo registro en blanco
      //this.Entorno.records.records.push(item);
      this.Entorno.records.records.unshift(item);

      // indice del registro en el array
      //var index= this.Entorno.records.records.length - 1;
      var index = 0;

      // edito la linea
      this.Entorno.estado = "nuevo";

      // guardo el índice de la linea en edición
      this.Entorno.grid.row.row_inEdition = index;
      console.log(this.Entorno);
    },

    // borramos linea pulsada
    borrar(evt) {
      this.$root.$alert
        .open("¿Desea eliminar el registro?", "confirm")
        .then(r => {
          if (!r) return;

          this.Entorno.grid.row.row_inEdition = evt.row.index;
          this.borrar_registro(evt.row.item.id);
        });
    },

    async borrar_registro(id) {
      console.log("elimino registro " + id);

      // elimino el registro recibido
      var args = {
        tipo: "mto",
        api: this.schema.php,
        accion: 3,
        fn_args: { id: id }
      };
      var r = await this.$store.dispatch("ajaxRequest", { args: args });
      this.borrar_fin(r);
    },

    borrar_fin(r) {
      // muestro mensaje de error si se no se ha eliminado correctamente
      if (!r) {
        this.$root.$alert.open("Error al borrar el registro", "error");
        return;
      }

      this.Entorno.estado = "";
      this.Entorno.records.records.splice(
        this.Entorno.grid.row.row_inEdition,
        1
      );
      this.Entorno.grid.row.row_inEdition = null;
      this.$root.$alert.open("Registro borrado satisfactoriamente", "success");
    },

    //
    async guardar(evt) {
      var args = {
        tipo: "mto",
        api: this.schema.php,
        accion: 4,
        fn_args: {
          id: evt.row.item.id,
          estado: this.Entorno.estado,
          changes: this.get_changes(evt)
        }
      };
      console.log(args);

      this.guardar_fin(
        await this.$store.dispatch("ajaxRequest", { args: args })
      );
    },

    guardar_fin(r) {
      if (r === false) {
        // muestro mensaje de error
        this.$root.$alert.open("Error al guardar el registro", "error");
        return r;
      }

      // si venimos de un 'nuevo', actualizo id
      if (this.Entorno.estado === "nuevo")
        this.Entorno.records.records[
          this.Entorno.grid.row.row_inEdition
        ].id = r;

      // recargo registros del Grid
      this.get_records();

      //
      this.Entorno.estado = "";
      this.Entorno.grid.row.row_inEdition = null;
      this.$root.$alert.open("Registro guardado correctamente!", "success", 1800);
    },

    //
    get_changes(evt) 
    {
        var changes= {};
        var ctrls= this.schema.ctrls;
        for (const key in ctrls) {            
            if (this.Entorno.grid.row.read_row[key]!== evt.row.item[key]) changes[key]= evt.row.item[key];
        }

      //console.log('obtengo cambios...', evt.row.item);
      console.log('CHANGES: =>', changes);
      return changes;
    }
  }
};
